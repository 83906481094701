@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Alegreya);

body {
    margin: 0;
    padding: 0;
}

.content-box {
    width:75%; 
    margin: 0 auto;
    background-color: #FFFFFF;
    padding: 25px;
    font-family: 'Inter';
    font-size: 16px;
    box-shadow: 5px 5px 5px 5px rgba(0,0,0,.1);
    min-height: 300px;
    text-align: justify;
    overflow: auto;    
}

.content-box h3, .content-box h5, .content-box h4, .content-box b, .content-box h2{
  font-family: 'Montserrat';
}